import _slicedToArray from "/home/myaccountconso/web/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var __jsx = React.createElement;
import React, { useState } from 'react';
import useInputState from '../hooks/forms/useInputState';
import RequestTokenForm from './RequestTokenForm';
import VerifyTokenForm from './VerifyTokenForm';
import ConfirmPasswordForm from './ConfirmPasswordForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import Button from 'react-bootstrap/Button';

var ForgotPasswordForm = function ForgotPasswordForm(props) {
  var email = props.email,
      setEmail = props.setEmail,
      resetStep = props.resetStep,
      editResetStep = props.editResetStep,
      tryLogin = props.tryLogin;

  var _useInputState = useInputState(''),
      _useInputState2 = _slicedToArray(_useInputState, 2),
      token = _useInputState2[0],
      setToken = _useInputState2[1];

  var backToLogin = function backToLogin() {
    tryLogin(true);
  };

  return __jsx(React.Fragment, null, resetStep == 'email' && __jsx(RequestTokenForm, {
    email: email,
    setEmail: setEmail,
    changeStep: editResetStep
  }), resetStep == 'token' && __jsx(VerifyTokenForm, {
    token: token,
    email: email,
    setToken: setToken,
    changeStep: editResetStep
  }), resetStep == 'password' && __jsx(ConfirmPasswordForm, {
    token: token,
    email: email,
    tryLogin: tryLogin,
    changeStep: editResetStep
  }), __jsx("hr", {
    className: "my-4"
  }), __jsx("p", {
    className: "text-center"
  }, __jsx(FontAwesomeIcon, {
    icon: faArrowLeft
  }), __jsx(Button, {
    variant: "link",
    onClick: backToLogin
  }, "Login")));
};

export default ForgotPasswordForm;