import _slicedToArray from "/home/myaccountconso/web/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var __jsx = React.createElement;
import React, { useState } from 'react';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons/faUnlockAlt';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useInputState from '../../components/hooks/forms/useInputState';
import styles from '../styles/login/LoginForm.module.css';

var LoginBlock = function LoginBlock(props) {
  var xs = props.xs,
      sm = props.sm,
      lg = props.lg,
      redirectUrl = props.redirectUrl,
      openAccountLink = props.openAccountLink,
      defaultEmail = props.defaultEmail;

  var _useState = useState(true),
      tryLogin = _useState[0],
      editTryLogin = _useState[1];

  var _useInputState = useInputState(defaultEmail ? defaultEmail : ''),
      _useInputState2 = _slicedToArray(_useInputState, 3),
      email = _useInputState2[0],
      setEmail = _useInputState2[1],
      resetEmail = _useInputState2[2];

  var _useState2 = useState('email'),
      resetStep = _useState2[0],
      editResetStep = _useState2[1];

  var pageTitleReset = function pageTitleReset(resetStep) {
    switch (resetStep) {
      case 'email':
        return 'Recuperează parola';

      case 'token':
        return 'Verifică-ți contul de email';

      case 'password':
        return 'Completează noua parolă';
    }
  };

  var form = tryLogin ? __jsx(LoginForm, {
    editTryLogin: editTryLogin,
    setEmail: setEmail,
    email: email,
    redirectUrl: redirectUrl,
    openAccountLink: openAccountLink
  }) : __jsx(ForgotPasswordForm, {
    tryLogin: editTryLogin,
    email: email,
    setEmail: setEmail,
    resetStep: resetStep,
    editResetStep: editResetStep
  });
  var title = tryLogin ? 'Conectează-te în cont' : pageTitleReset(resetStep);
  var icon = tryLogin ? faUserCircle : faUnlockAlt;
  return __jsx(Col, {
    xs: xs,
    sm: sm,
    lg: lg,
    className: "bg-white p-5 mx-auto ".concat(styles.frameBorder)
  }, __jsx("h1", {
    className: "heading2 black-text text-center"
  }, title), __jsx("p", {
    className: "text-center green"
  }, __jsx(FontAwesomeIcon, {
    icon: icon,
    size: "5x",
    height: 60,
    width: 60
  })), form);
};

export default LoginBlock;