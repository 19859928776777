import EmailValidator from './EmailValidator';
export var EmailField = function EmailField(value) {
  if (value == '') {
    return {
      status: false,
      msg: 'Completează adresa de email'
    };
  } else if (!EmailValidator(value)) {
    return {
      status: false,
      msg: 'Adresa de email incorectă'
    };
  } else {
    return {
      status: true,
      msg: ''
    };
  }
};
export var PasswordFieldLogin = function PasswordFieldLogin(value) {
  if (value == '') {
    return {
      status: false,
      msg: 'Completează corect parola'
    };
  } else {
    return {
      status: true,
      msg: ''
    };
  }
};
export var PasswordField = function PasswordField(value) {
  if (value == '') {
    return {
      status: false,
      msg: 'Completează corect parola'
    };
  } else if (value.length < 6) {
    return {
      status: false,
      msg: 'Parola trebuie să aibă minim 6 caractere'
    };
  } else {
    return {
      status: true,
      msg: ''
    };
  }
};
export var PasswordConfirmationField = function PasswordConfirmationField(value, password) {
  if (value == '') {
    return {
      status: false,
      msg: 'Repetă parola introdusă mai sus'
    };
  } else if (value != password) {
    return {
      status: false,
      msg: 'Parolele nu coincid'
    };
  } else {
    return {
      status: true,
      msg: ''
    };
  }
};
export var TermsField = function TermsField(value) {
  if (value) {
    return {
      status: true,
      msg: ''
    };
  } else {
    return {
      status: false,
      msg: 'Termenii de mai sus sunt obligatorii'
    };
  }
};
export var PINField = function PINField(value) {
  if (value == '') {
    return {
      status: false,
      msg: 'Completează corect codul'
    };
  } else if (value.length < 4 || value.length > 4 || isNaN(value)) {
    return {
      status: false,
      msg: 'Cod incorect'
    };
  } else {
    return {
      status: true,
      msg: ''
    };
  }
};