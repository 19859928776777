import { useState } from 'react';

function useInputState(initialVal) {
  var _useState = useState(initialVal),
      value = _useState[0],
      setValue = _useState[1];

  var edit = function edit(e) {
    setValue(e.target.value);
  };

  var reset = function reset() {
    setValue("");
  };

  return [value, edit, reset];
}

export default useInputState;
export var useEditState = function useEditState(initialVal) {
  var _useState2 = useState(initialVal),
      value = _useState2[0],
      setValue = _useState2[1];

  var edit = function edit(e) {
    setValue(e.target.value);
  };

  return [value, edit];
};